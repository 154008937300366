@import ../../../../styles/helpers

.panel
    position: absolute
    top: 16px
    left: calc(100% + 24px)
    display: flex
    flex-direction: column
    +d
        top: 0
        left: auto
        right: -88px
    +m
        display: none

.avatar,
.brand,
.share
    &:not(:last-child)
        margin-bottom: 24px

.avatar,
.brand,
.share
    flex-shrink: 0
    width: 64px
    height: 64px

.avatar
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.brand
    position: relative
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    background: $n
    +dark
        background: $n6
    img
        max-height: 32px

.counter
    position: absolute
    top: 0
    left: 0
    min-width: 20px
    padding: 0 4px
    background: $n7
    border-radius: 50%
    font-size: 14px
    line-height: 20px
    color: $n1
    +dark
        background: $n1
        color: $n7

.share
    background: $n
    border-radius: 50%
    +dark
        background: $n6
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1
