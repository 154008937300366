@import ../../../styles/helpers

.profile
    display: flex
    align-items: flex-start
    margin-bottom: 40px
    padding-bottom: 40px
    border-bottom: 1px solid $n3
    +d
        margin-bottom: 32px
        padding-bottom: 32px
    +t
        display: block
    +m
        margin-bottom: 32px
        padding-bottom: 32px
    +dark
        border-color: $n6

.details
    display: flex
    align-items: center
    flex-grow: 1
    +t
        margin-bottom: 16px
    +m
        margin-bottom: 12px

.avatar
    position: relative
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 16px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.add
    position: absolute
    right: 0
    bottom: 0
    width: 24px
    height: 24px
    border: 2px solid $n
    border-radius: 50%
    background: $p1
    font-size: 0
    transition: background .2s
    svg
        fill: $n
    &:hover
        background: darken($p1, 5)

.man
    +m
        font-size: 20px

.info
    margin-top: 8px
    +title1-s
    color: $n4
    +m
        margin-top: 4px
        font-size: 15px

.contacts
    display: flex
    align-items: center
    flex-shrink: 0
    margin-left: 40px
    +t
        margin-left: 0

.socials
    display: flex
    align-items: center

.social
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1
    &:not(:last-child)
        margin-right: 32px

.button
    margin-left: 32px
    +t
        margin-left: auto
    +m
        min-width: 132px