@import ../../../../styles/helpers

.details
    padding: 24px
    flex-grow: 1
    +m
        padding: 16px
        overflow: hidden

.head
    display: flex
    align-items: center
    margin-bottom: 40px
    +m
        flex-wrap: wrap
        margin-bottom: 32px

.nav
    display: flex
    margin-right: auto
    +m
        width: 100%
        margin: 0 0 16px

.link
    padding: 8px 16px
    border-radius: 8px
    +base1-s
    color: $n4
    transition: all .2s
    +m
        flex: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.btns
    display: flex
    align-items: center

.favorite
    margin-right: 16px
    svg
        fill: $p3
    &:hover
        box-shadow: inset 0 0 0 1px $p3
        background: $p3
        color: $n1
        +dark
            box-shadow: inset 0 0 0 1px $p3
        svg
            fill: $n1

.buy
    +t
        padding: 0 16px
    +m
        flex-grow: 1
    .inner
        span
            +t
                display: none

.price
    margin-right: 20px
    padding-right: 20px
    border-right: 1px solid #4493FC
    box-shadow: 1px 0 0 0 #186FE3
    line-height: 48px
    +t
        margin-right: 16px
        padding-right: 16px
    +m
        margin-right: 12px
        padding-right: 12px