@import ../../../../../styles/helpers

.products
    margin-top: 64px
    padding-top: 64px
    border-top: 1px solid $n3
    +t
        margin-top: 48px
        padding-top: 48px
    +m
        margin-top: 32px
        padding-top: 32px
    +dark
        border-color: $n6

.head
    display: flex
    align-items: center
    margin-bottom: 32px

.title
    margin-right: auto

.wrapper
    margin: 0 -12px
    +m
        margin: 0



