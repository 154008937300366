@import ../../styles/helpers

.actions
    position: relative
    display: inline-block
    z-index: 3
    &.active
        z-index: 10
        .head
            background: $n3
            svg
                fill: $n7
            +dark
                background: $n6
                svg
                    fill: $n1
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)
    &.small
        .head
            width: 32px
            height: 32px
            svg
                width: 20px
                height: 20px
        .body
            right: -8px
            &:before
                right: 14px
    &.up
        .body
            top: auto
            bottom: calc(100% + 12px)
            box-shadow: 0px 20px 32px -6px rgba(0, 0, 0, 0.08), 0px 0px 7px -4px rgba(0, 0, 0, 0.05), 0px 16px 24px -4px rgba(0, 0, 0, 0.1)
            &:before
                top: 100%
                bottom: auto
                transform: rotate(180deg)

.head
    position: relative
    width: 40px
    height: 40px
    border-radius: 50%
    background: $n
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    user-select: none
    transition: background .2s
    svg
        transition: fill .2s
        fill: $n4
    &:hover
        svg
            fill: $p1
    +dark
        background: $n8
        &:hover
            svg
                fill: $p1

.body
    position: absolute
    top: calc(100% + 12px)
    right: -12px
    width: 264px
    padding: 12px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    +dark
        background: $n8
        border-color: $n6
    &:before
        content: ""
        position: absolute
        right: 20px
        bottom: 100%
        width: 20px
        height: 10px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")

.option
    display: flex
    align-items: center
    width: 100%
    padding: 12px
    border-radius: 12px
    +base1-s
    color: $n4
    transition: all .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    &:hover,
    &.selectioned
        background: $n3
        color: $n7
        svg
            fill: $n7
        +dark
            background: transparent
            color: $n1
            svg
                fill: $n1
