import React from "react";
import cn from "classnames";
import styles from "./Home.module.sass";
// import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
// import PopularProducts from "../../components/PopularProducts";
// import Comments from "./Comments";
// import RefundRequests from "../../components/RefundRequests";
import ProTips from "./ProTips";
import MoreCustomers from "./MoreCustomers";
import ProductViews from "./ProductViews";

const Home = () => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview className={styles.card} />
          <ProTips className={styles.card} />
          <MoreCustomers />
        </div>
        <div className={styles.col}>
          <ProductViews className={styles.card} />
        </div>
      </div>
      {/* <TooltipGlodal /> */}
    </>
  );
};

export default Home;
