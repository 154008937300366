@import ../../../../../styles/helpers

.title
    margin-bottom: 12px
    +m
        font-size: 24px

.info
    margin-bottom: 12px
    +title1-m
    color: $n4
    +m
        font-size: 14px

.line
    display: flex
    align-items: center

.author
    display: flex
    align-items: center
    +base2
    color: $n4
    span
        margin-left: 4px
        color: $n7
        +dark
            color: $n1

.avatar
    flex-shrink: 0
    width: 32px
    height: 32px
    margin-right: 12px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.rating
    display: flex
    align-items: center
    margin-left: 12px
    +base2
    svg
        margin-right: 8px
        fill: #FFC554

.counter
    margin-left: 4px
    color: $n4

.gallery
    position: relative
    margin-top: 40px
    +m
        margin-top: 32px
    .button
        position: absolute
        left: 12px
        bottom: 12px
        +m
            bottom: 5px
        +dark
            box-shadow: inset 0 0 0 2px $n6
            background: $n7
            color: $n1
            &:hover
                background: $n6

.preview
    img
        width: 100%
        border-radius: 12px

.row
    display: flex
    margin-top: 40px
    +t
        display: block
    +m
        margin-top: 32px

.col
    &:first-child
        flex-grow: 1
        padding-right: 64px
        +d
            padding-right: 48px
        +t
            padding-right: 0
    &:nth-child(2)
        flex-shrink: 0
        width: 308px
        +t
            width: 100%
            margin-top: 32px

.subtitle
    margin-bottom: 32px
    +t
        margin-bottom: 24px
    +m
        margin-bottom: 12px

.content
    font-weight: 500
    color: $n5
    +dark
        color: $n3
    p
        &:not(:last-child)
            margin-bottom: 16px

.features
    +m
        margin-top: 12px
    li
        position: relative
        padding-left: 40px
        color: $n5
        +dark
            color: $n3
        &:before
            content: ""
            position: absolute
            top: 0
            left: 0
            width: 24px
            height: 24px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%23b5e4ca'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        &:not(:last-child)
            margin-bottom: 20px
            padding-bottom: 20px
            border-bottom: 1px solid $n3
            +m
                margin-bottom: 16px
                padding-bottom: 16px
            +dark
                border-color: $n6