@import ../../../styles/helpers

.nav
    display: flex
    margin-bottom: 32px
    padding: 8px
    background: $n2
    border-radius: 20px
    +m
        margin-bottom: 24px
    +dark
        background: $shades4

.item
    &.active
        background: $n1
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        +dark
            background: $n6
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)
            