@import ../../../../styles/helpers

.item
    position: relative
    display: flex
    align-items: center
    padding: 12px
    border-radius: 12px
    transition: background .2s
    +m
        padding: 8px
    &:hover
        background: $n3
        +dark
            background: $n6
        +m
            background: none
        .avatar.online:before
            border-color: $n3
            +m
                border-color: $n
            +dark
                border-color: $n6
                +m
                    border-color: $n8
    &:after
        content: ""
        position: absolute
        top: 20px
        right: 12px
        width: 12px
        height: 12px
        border-radius: 50%
        background: $shades1
        +m
            top: 14px
    &.new
        &:after
            background: $p1
    &:not(:last-child)
        margin-bottom: 8px
        &:before
            content: ""
            position: absolute
            left: 12px
            right: 12px
            bottom: 0
            height: 1px
            background: $n3
            +dark
                background: transparent
    &:nth-child(n+3)
        +m
            display: none
        +a
            display: flex
    &:nth-child(n+6)
        +a
            display: none

.avatar
    position: relative
    flex-shrink: 0
    width: 56px
    height: 56px
    +m
        width: 48px
        height: 48px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover
    &.online
        &:before
            content: ""
            position: absolute
            top: -2px
            left: -2px
            width: 20px
            height: 20px
            border: 4px solid $n
            background: $p2
            border-radius: 50%
            transition: border-color .2s
            +dark
                border-color: $n8

.details
    flex: 0 0 calc(100% - 56px)
    width: calc(100% - 56px)
    padding-left: 12px

.line
    display: flex
    align-items: center
    margin-bottom: 4px
    padding-right: 24px

.subtitle
    margin-right: auto
    font-weight: 700
    color: $n7
    +dark
        color: $n1

.time
    margin-left: 16px
    +caption1
    color: $shades1

.content
    padding-right: 40px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-weight: 500
    color: $n4