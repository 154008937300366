import React, { StrictMode } from "react";
import { render } from "react-dom";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Course from "./screens/Courses";

const App = () => {
  return (
    <StrictMode>
      <BrowserRouter>
        <h1>Hello From App</h1>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Page title="Dashboard">
                <Home />
              </Page>
            }
          />
          <Route
            exact
            path="/course"
            element={
              <Page>
                <Course />
              </Page>
            }
          />
        </Routes>
      </BrowserRouter>
    </StrictMode>
  );
};

render(React.createElement(App), document.getElementById("root"));
