@import ../../../../styles/helpers

.item
    display: flex
    align-items: center
    padding: 12px
    border-radius: 12px
    cursor: pointer
    transition: all .2s
    +x
        padding: 6px
    &:hover
        background: $n2
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1)
        +dark
            background: $n7
            box-shadow: none
    &:not(:last-child)
        margin-bottom: 8px

.preview
    flex-shrink: 0
    width: 96px
    height: 72px
    margin-right: 16px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 8px

.title
    margin-bottom: 8px

.line
    display: flex
    align-items: center

.status
    margin-right: 4px
    color: $n7

.user
    display: flex
    align-items: center
    padding: 1px 8px 1px 1px
    border: 1px solid $n3
    border-radius: 4px
    +dark
        border-color: $n6

.avatar
    flex-shrink: 0
    width: 20px
    height: 20px
    margin-right: 4px
    font-size: 0
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 2px

.time
    +caption2
    color: $n4
