@import ../../styles/helpers

.card
    position: relative
    padding: 24px
    background: $n1
    border-radius: 8px
    +m
        padding: 16px
    +dark
        background: $n7

.head
    display: flex
    align-items: center
    min-height: 40px
    margin-bottom: 32px
    +m
        margin-bottom: 24px

.title
    margin-right: auto
    +m
        line-height: 40px
