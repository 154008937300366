@import ../../../styles/helpers

.info
    margin-bottom: 16px
    font-weight: 500
    color: $n4

.btns
    display: flex
    margin: 0 -8px
    +m
        margin: 0 -6px

.button
    flex: 0 0 calc(33.333% - 16px)
    width: calc(33.333% - 16px)
    margin: 0 8px
    +m
        flex: 0 0 calc(33.333% - 12px)
        width: calc(33.333% - 12px)
        margin: 0 6px
        font-size: 0
        svg
            margin: 0 !important